<template>
	<div class="ArticleList">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>挂号管理</el-breadcrumb-item>
			<el-breadcrumb-item>报告列表</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card class="box-card">
			<el-table :data="listData" border style="width: 100%">
        <el-table-column
          type="selection"
          width="45">
        </el-table-column>
				<el-table-column prop="id" label="编号" width="50">
				</el-table-column>
				<el-table-column prop="title" label="标题" width="280">
				</el-table-column>
        <el-table-column prop="jiuzhenren.jiuzhenren_name" label="就诊人" width="100">
        </el-table-column>
        <el-table-column prop="jiuzhenren.age" label="年龄" width="50">
        </el-table-column>
        <el-table-column prop="jiuzhenren.sex" label="性别" width="50">
        </el-table-column>
        <el-table-column prop="jiuzhenren.phone" label="电话" width="100">
        </el-table-column>
        <el-table-column prop="jiuzhenren.address" label="地址" width="380">
        </el-table-column>
				<el-table-column prop="created_at" label="时间">
				</el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- 修改 -->
<!--            <el-button type="primary" icon="el-icon-setting" size="mini"
              @click="editEmsInfo(scope.row.id)"
            ></el-button> -->
            <!-- 删除 -->
            <el-button
            type="primary"
            size="mini"
            icon="el-icon-delete"
            @click="delNews(scope.row.id)"></el-button>
          </template>

        </el-table-column>
			</el-table>

			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage4" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</el-card>

	</div>
</template>

<script>
export default {
  name: 'ArticleList',
  data () {
    return {
      listData: [],
      total: 100,
      pageSize: 10,
      currentPage4: 1,
      params: {
        search_time: '',
        keywords: '',
        page: 1,
        limit: 10
      },
      gridData: [],
      addNews:{
        dialogVisible: false,
        newsForm:{}
      }
    }
  },
  created () {
    this.getArticleList()
  },
  methods: {
    async getArticleList () {
      const {
        data: res
      } = await this.$http.get(
        'api/admin/baogao/list',{
          params: this.params
        }
      )
      this.listData = res.data.data
      this.total = res.data.total
      console.log(this.listData)
    }, // 每页显示多少条
    handleSizeChange (newSize) {
      console.log(newSize)
      this.params.limit = newSize
      this.getArticleList()
    }, // 第几页
    handleCurrentChange (newPage) {
      console.log(newPage)
      this.params.page = newPage
      this.getArticleList()
    },
    // 修改ems信息 获取数据
    async editEmsInfo(id) {
      this.$router.push({
        path:'edit_news',
        query:{
          id:id
        }
      })
    },
    // 删除
    async delNews(id) {
      const {
        data: res
      } = await this.$http.post(
        'api/admin/baogao/del',{
          'id':id
        }
      )
      if (res.status) return this.$message.error(res.message)
      this.$message.success(res.message)
      this.getArticleList()
    }

  }
}
</script>
<style>
</style>
